import Link from 'next/link';
import useStyles from 'static/styles/jss/base/globalPages';
import { Container, Row, Col } from 'reactstrap';
import { NextSeo } from 'next-seo';
import WithAuth from 'hoc/WithAuth';
import { useTranslations } from 'next-intl';

import LoginForm from 'Components/Entry/LoginForm';
import Canonical from 'Components/Canonical';

import { getRouterBasePath } from 'utils/meta';

import AuthLayout from './_layouts/auth';

const Login = () => {
  const t = useTranslations();
  const styles = useStyles();

  const { title, description } = getRouterBasePath('/login');

  return (
    <>
      <Canonical />
      <NextSeo title={title} description={description} />
      <Container>
        <Row>
          <Col xs={12} sm={12} md={2} xl={3}></Col>
          <Col xs={12} sm={12} md={8} xl={6}>
            <div className={styles['login-page']}>
              <div className={styles['login-page-form--block']}>
                <LoginForm />
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                  <span style={{ color: '#c7c7c7' }}>{t('login_text')}</span>
                  <Link
                    href={'/register'}
                    style={{
                      textDecoration: 'none !important',
                      color: 'red !important',
                    }}
                  >
                    {t('login_text_create_one')}
                  </Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default WithAuth(Login, AuthLayout, false);
