import React, { useState } from 'react';
import jwt_decode from 'jwt-decode';
import { useRouter } from 'next/router';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslations } from 'next-intl';

import { Button, InlineToast, SimpleInput } from 'Components/Shared/UI';

import { saveToStorage } from 'utils/persist';
import ValidatorsState from 'utils/validators';
import { Cookie } from 'utils/handlers';

import { setUserData, setDictionary } from 'store/actions';
import { validators } from 'store/reselect';

import API from 'services/api';

import useStyles from './styles';

const LoginForm = () => {
  const t = useTranslations();
  const validatorsPattern = useSelector(validators());
  const validatorsExec = ValidatorsState({
    emailPattern: validatorsPattern.emailPattern,
  });
  const dispatch = useDispatch();
  const styles = useStyles();

  const [loadingIcon, setLoadingIcon] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [optionalNotification, setOptionalNotification] = useState(false);
  const { register, handleSubmit, errors, reset, watch, setValue } = useForm({
    mode: 'onKeypress',
  });
  const router = useRouter();

  const email = watch('email', '');
  const password = watch('password', '');

  const onLogin = async dataForm => {
    try {
      setLoadingIcon(true);
      const { password, email } = dataForm;

      const { data } = await API.getAuthTokens({
        password,
        email,
        cookie_hash: Cookie.getCookieByKey('ch'),
        user_agent: navigator.userAgent,
        ...(dataForm.remember_me ? { remember_me: true } : {}),
      });

      if (data) {
        const userTokenData = jwt_decode(data.access_token);
        const { id, language } = userTokenData;
        const currentLanguage = Cookie.getCookieByKey('lang');

        if (currentLanguage !== language) {
          await new Promise(resolve => {
            dispatch(setDictionary({ locale: language, cb: resolve }));
          });
        }
        Cookie.setToken(data.access_token);
        saveToStorage('refreshToken', data.refresh_token);
        dispatch(setUserData({ processLogin: true }));
        const userData = await API.getAccount({ id });
        dispatch(
          setUserData({
            ...userTokenData,
            ...userData.data,
            processLogin: false,
          }),
        );
        router.push('/my-sexjobs/ads', '/my-sexjobs/ads', {
          locale: language,
          shallow: true,
        });
      }
    } catch (err) {
      setLoadingIcon(false);
      reset();
    }
  };

  return (
    <form onSubmit={handleSubmit(onLogin)} className={styles['login-form']}>
      <h5 className="title-content">{t('login_title')}</h5>
      <div className="form-fields">
        {optionalNotification && (
          <InlineToast
            title="Optional notification"
            description={optionalNotification}
            type="info"
          />
        )}
        <SimpleInput
          registerRef={register(validatorsExec.EMAIL_VALIDATOR)}
          type="email"
          name="email"
          value={email}
          errors={errors.email?.message}
          label={{ title: t('login_input_1_text'), color: '#fff' }}
          fullWidth
          margin={[0, 0, 16, 0]}
          required
          withoutRequiredText
          onChange={e => setValue('email', e.target.value)}
        />
        <SimpleInput
          registerRef={register()}
          value={password}
          name="password"
          type="password"
          fullWidth
          required
          withoutRequiredText
          passwordEye
          label={{ title: t('login_input_2_text'), color: '#fff' }}
          margin={[0, 0, 16, 0]}
          onChange={e => setValue('password', e.target.value)}
        />
        <span
          className="forgot-your-pass"
          onClick={() =>
            router.push({
              pathname: '/reset-password',
            })
          }
        >
          {t('login_forgot_password_text')}
        </span>
        <SimpleInput
          theme="dark"
          registerRef={register()}
          type="checkbox"
          name="remember_me"
          label={{
            title: t('login_remember_me_text'),
            color: '#fff',
            forId: 'remember-me',
          }}
          margin={[16, 0, 16, 0]}
        />
        <Button
          disabled={loadingIcon}
          icon={{ direction: 'left', loading: loadingIcon }}
          type="submit"
          text={t('login_button_text')}
          typeButton="primary"
          direction="center"
          width={220}
          margin={[30, 0, 0, 0]}
        />
      </div>
    </form>
  );
};
export default React.memo(LoginForm);
