import React from 'react';
import jwt_decode from 'jwt-decode';

import { Cookie } from 'utils/handlers';
import { ENV } from 'utils/constants';

import { setUserData } from 'store/actions';

const privateRoutes = ['/login', '/register', '/reset-password'];

const WithAuth = (Component, Layout, auth, layoutProps = {}) => {
  class LoggedHOC extends React.Component {
    static Layout = Layout;

    static _getInitialProps = async props => {
      let isMount = true;
      const { req, res, store } = props.ctx;

      const additionalProps =
        (Component?.getInitialProps &&
          (await Component.getInitialProps?.(props))) ||
        {};
      const token =
        Cookie.getTokenByReq(req?.headers?.cookie || '') || Cookie.getToken;

      if (auth) {
        if (!token) {
          if (!res && typeof window !== 'undefined') {
            isMount = false;
            window.location.href = '/login';
          } else {
            res.writeHead(302, { Location: '/login' });
            res.end();
          }
        } else if (!store?.getState().auth.user?.id) {
          const userTokenData = jwt_decode(token);
          const res = await fetch(
            `${ENV.baseUrl}/account/${userTokenData.id}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            },
          );
          const userData = await res.json();
          store.dispatch(setUserData({ ...userTokenData, ...userData }));
        }
      } else if (token) {
        if (!res && typeof window !== 'undefined') {
          isMount = false;
          window.location.href = '/';
        } else if (privateRoutes.includes(req.url)) {
          res.writeHead(302, { Location: '/' });
          res.end();
        }
      }
      return {
        ...additionalProps,
        layoutProps,
        isMount,
      };
    };

    static get getInitialProps() {
      return LoggedHOC._getInitialProps;
    }

    static set getInitialProps(value) {
      LoggedHOC._getInitialProps = value;
    }

    constructor(props) {
      super(props);
    }

    render() {
      return this.props.isMount ? <Component {...this.props} /> : '';
    }
  }

  return LoggedHOC;
};
export default WithAuth;
