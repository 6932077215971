import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  'login-form': {
    '& .forgot-your-pass': {
      fontSize: '14px',
      color: '#99c7e2',
      cursor: 'pointer',
    },
  },
});
